<template>
    <dashboard-layout>
        <v-row>
            <v-col cols="12" md="7" lg="5">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    New Artists
                                </v-card-title>
                                <ChartView v-if="artistsChartReady" id="artists" :data="chartsData.artists"/>
                                <v-skeleton-loader v-else type="image"/>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    New Venues
                                </v-card-title>
                                <ChartView v-if="venuesChartReady" id="venues" :data="chartsData.venues"/>
                                <v-skeleton-loader v-else type="image"/>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    New Fans
                                </v-card-title>
                                <ChartView v-if="fansChartReady" id="fans" :data="chartsData.fans"/>
                                <v-skeleton-loader v-else type="image"/>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" md="5" lg="7">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" lg="6"
                            v-for="card in cards" :key="card.title"
                            >
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title class="flex-nowrap align-start">
                                    <v-icon color="primary" class="mr-2">{{ card.icon }}</v-icon>
                                    {{ card.title }}
                                </v-card-title>
                                <v-card-text v-if="card.text">
                                    {{ card.text }}
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        :to="card.to"
                                        small
                                        text
                                        color="accent"
                                    >
                                        {{ card.toLabel }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import ChartView from "../../components/ChartView";
import cards from "./cards";
import {chartsData} from '@/plugins/charts-data'
import Artist from "@/models/Artist";
import Venue from "@/models/Venue";
import Fan from "@/models/Fan";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "Index",
    components: {DashboardLayout, ChartView},
    data: function () {
        return {
            cards,
            artistsChartReady: false,
            venuesChartReady: false,
            fansChartReady: false,
            chartsData: {
                artists: null,
                venues: null,
                fans: null,
            },
        }
    },
    mounted() {
        this.getNewArtistsCounts()
        this.getNewVenuesCounts()
        this.getNewFansCounts()
    },
    methods: {
        async getNewArtistsCounts() {
            this.artistsChartReady = false;
            const artistsNewCounts = await Artist.getNewCounts();
            this.chartsData.artists = chartsData(artistsNewCounts, '#4801a5');
            this.artistsChartReady = true;
        },
        async getNewVenuesCounts() {
            this.venuesChartReady = false;
            const venuesNewCounts = await Venue.getNewCounts();
            this.chartsData.venues = chartsData(venuesNewCounts, '#ce790a');
            this.venuesChartReady = true;
        },
        async getNewFansCounts() {
            this.fansChartReady = false;
            const fansNewCounts = await Fan.getNewCounts();
            this.chartsData.fans = chartsData(fansNewCounts, '#4801a5');
            this.fansChartReady = true;
        },
    }
}
</script>

<style scoped>

</style>
