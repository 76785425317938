export default [
    {
        to: {name: 'dashboard.favourites'},
        toLabel: 'View all',
        icon: 'mdi-heart',
        title: 'Favorites',
        text: 'Recently added: • first • second'
    },
    {
        to: {name: 'admin.artist-submissions.index'},
        toLabel: 'View submissions',
        icon: 'mdi-email',
        title: 'Artist Submissions',
        text: 'New Artist Submissions: 3'
    },
    {
        to: {name: 'admin.venue-submissions.index'},
        toLabel: 'View submissions',
        icon: 'mdi-email',
        title: 'Venue Submissions',
        text: 'New Venue Submissions: 5'
    },
    {
        to: {name: 'admin.tracks.index'},
        toLabel: 'View all',
        icon: 'mdi-playlist-music',
        title: 'Tracks',
        text: 'Total Tracks: 38'
    },
    {
        to: {name: 'admin.videos.index'},
        toLabel: 'View all',
        icon: 'mdi-video',
        title: 'Videos',
        text: 'Total Videos: 18'
    },
    {
        to: {name: 'admin.gigs.index'},
        toLabel: 'View all',
        icon: 'mdi-calendar',
        title: 'Gigs',
        text: 'Total Gigs: 27'
    },
]
